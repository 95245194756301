<template>
    <div>
      <!-- error handelr -->
      <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
        variant="danger">
        <h4 class="alert-heading">
          Alert
        </h4>
        <div class="alert-body">
          <ul v-for="values in errors_back" :key="values">
            <li v-for="value in values" :key="value">{{ value }}</li>
          </ul>
        </div>
      </b-alert>
      <b-card title="Subscriptions">
        <b-row>
          <!-- sorting -->
          <b-col md="4" class="my-1">
            <b-form-group label="Sort By" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
              class="mb-0">
  
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" @change="sortingTable" v-model="sortBy" :options="sortOptions"
                  class="w-75">
                </b-form-select>
                <b-form-select @change="sortingTable" v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
  
  
            </b-form-group>
          </b-col>
  
           <!-- filter -->
           <b-col md="4" class="my-1">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="fetchData">
                    Search
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
  
  
           <!-- sorting Status -->
           <b-col md="2" class="my-1">
            <b-form-group
              label="Status"
              label-cols-sm="4"
              label-align-sm="right"
              label-size="sm"
            >
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                style="z-index: 9999999;"
                v-model="status"
                class="border mr-0"
                size="sm"
                :text="statusName"
                variant="flat-primary"
              >
                <b-dropdown-item @click="changeStatus('All', 'all')">
                  All
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeStatus(item.text, item.value)"
                  v-for="(item, index) in statusFilterOption" :key="index"
                >
                  {{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-col>
  
          <b-col cols="1" class="my-1">
            <!-- print and save pdf & excel -->
            <div class="ml-auto mr-2">
              <b-dropdown style="height: 2.142rem; line-height: 0.5;" v-ripple.400="'rgba(113, 102, 240, 0.15)'" right
                variant="primary" size="sm" class="dropdown-icon-wrapper">
                <template #button-content><feather-icon icon="PrinterIcon" size="16" class="align-middle" />
                </template>
                <download-excel :data="exportItems">
                  <b-dropdown-item>
                    <feather-icon icon="DownloadIcon" /> Download
                    Excel</b-dropdown-item>
                </download-excel>
                <b-dropdown-divider />
                <b-dropdown-item @click="genPDF">
                  <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item>
                <b-dropdown-divider />
              </b-dropdown>
            </div>
          </b-col>
          <!-- start data table -->
          <b-col cols="12">
            <b-table id="tagTable" ref="admint" striped hover responsive show-empty :busy.sync="isBusy"
              class="position-relative" :per-page="perPage" @sort-changed="fetchData" :items="items" :fields="fields"
              :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
              @row-clicked="packagesQuickView">
  
              <template #cell(name)="data">
                <!-- <p>  ar:  {{ JSON.parse( data.item.name).name_ar}}</p> -->
                <p> {{ JSON.parse(data.item.name).name_en }} </p>
              </template>
  
  
              <template #cell(description)="data">
                <!-- <p>  ar:   <span v-html="JSON.parse( data.item.description).description_ar.substring(0, 8) + '..'"> </span></p> -->
                <p> <span v-html="JSON.parse(data.item.description).description_en.substring(0, 15) + '..'"> </span> </p>
              </template>
  
  
              <template #cell(packages)="data">
                <p> <span > {{ JSON.parse(data.item.packages.name).name_en }} </span> </p>
              </template>
  
  
              <template #cell(user)="data">
                <p> <span > {{ data.item.user.firstname + ' ' + data.item.user.lastname }} </span> </p>
              </template>
  
  
  
              <template #cell(payment_provider)="data">
                <p> <span > {{ data.item.payment_provider.name}} </span> </p>
              </template>
  
  
              
              <template #cell(subscription_status)="data">
                  <b-badge variant="primary" v-if="data.value">
                    {{ data.value }}
                  </b-badge>
              </template>
  
              
  
  
              <template #cell(filter_data)="data">
                <span v-if="data.item.filter_data != null">
  
                  <p v-if="JSON.parse(data.item.description).description_ar != null">
                    <span class="text-success">Start Date:</span> {{ JSON.parse(data.item.filter_data).description_ar }}
                  </p>
                  <p v-if="JSON.parse(data.item.description).description_en != null">
                    <span class="text-success">End Date:</span> {{ JSON.parse(data.item.description).description_en }}
                  </p>
                  <p v-if="JSON.parse(data.item.filter_data).gender != null">
                    <span class="text-success">Gender:</span> {{ JSON.parse(data.item.filter_data).gender }}
                  </p>
                  <p v-if="JSON.parse(data.item.filter_data).UserCategory != null">
                    <span class="text-success">User Category:</span> {{ JSON.parse(data.item.filter_data).UserCategory }}
                  </p>
                </span>
              </template>
  
  
             
  
              <template #cell(created_at)="data">
                {{ data.item.created_at | formatDate }}
              </template>
  
              <template #cell(updated_at)="data">
                {{ data.item.updated_at | formatDate }}
              </template>
  
  
              <template #cell(actions)="items">
                <!-- edit button value -->
                  <div class="d-flex p-1">
                    <!-- Show Order -->
                  <b-button
                    style="padding: 3px; margin: 3px; width: 35px; height: 35px;"
                    size="sm"
                    v-if="authPermissions.includes('show')"
                    variant="success"
                    type="submit"
                    :to="{ path: '/subscription/show/' + items.item.id }"
                  >
                    <feather-icon
                      class="mt-50 " 
                      icon="EyeIcon"
                    />
                  </b-button>
  
  
                  <b-button  style="padding: 3px; margin: 3px; width: 35px; height: 35px;" v-if="authPermissions.includes('update')" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    @click="updateSubscriptionModal(items.item)" v-b-modal.modal-prevent-closing variant="warning">
                    <feather-icon icon="EditIcon" />
                  </b-button>
  
  
                  <!-- Delete button value -->
                  <b-button  style="padding: 3px; margin: 3px; width: 35px; height: 35px;" v-if="authPermissions.includes('delete')" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm"
                    variant="danger" class="btn-cart " @click="deleteSubscription(items.item.id)">
                    <feather-icon icon="DeleteIcon"  />
                  </b-button>
                </div>
              </template>
  
            </b-table>
  
            <!-- model  update package -->
            <b-modal size="sm" id="modal-prevent-closing" cancel-variant="outline-secondary" @ok="updateSubscription(items.item)"
              ok-title="Update" cancel-title="Close" centered title="Update Status">
              <validation-observer ref="updatedSubscriptionForm">
                <b-form @submit.prevent>
                  <b-row>
                  
                     <!-- status -->
                     <b-col md="12" class="mt-4">
                      <validation-provider #default="{ errors }" name="Status" rules="required">
                        <b-form-group label="Status" label-for="Status" :state="errors.length > 0 ? false : null">
                          <v-select id="Status" v-model="updatedSubscription.subscription_status_array"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOption" :selectable="(option) => !option.value.includes('select_value')
                              " label="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
  
  
                  </b-row>
                </b-form>
              </validation-observer>
              <!-- error handelr -->
              <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
                variant="danger">
                <h4 class="alert-heading">Alert</h4>
                <div class="alert-body">
                  <ul v-for="(values, index) in errors_back" :key="index">
                    <li v-for="(value, indexVal) in values" :key="indexVal">
                      {{ value }}
                    </li>
                  </ul>
                </div>
              </b-alert>
            </b-modal>
  
  
            <b-modal v-model="modalShow" id="packages-quick-view-modal" hide-footer ref="modal" centered
              title="Subscription Details" size="lg">
  
              <b-row v-if="subscriptionsModal">
                <b-col md="12">
                  <p class="text-primary h4 mt-1">
                    Code: <span class="h5">{{ subscriptionsModal.code }}</span>
                  </p>
  
                 <p class="text-primary h4 mt-1" v-if="subscriptionsModal.user.firstname != null">
                    Name : <span class="text-dark ml-2">{{ subscriptionsModal.user.firstname  + ' '  + subscriptionsModal.user.lastname  }}</span>
                  </p>
  
  
                  <p class="text-primary h4 mt-1" v-if="subscriptionsModal.subscription_status != null">
                    Status : <span class="text-dark ml-2">{{ subscriptionsModal.subscription_status }}</span>
                  </p>
  
                
                  <p class="text-primary h4 mt-1">
                    price:
                    <b-badge variant="info">{{ subscriptionsModal.total_paid }}</b-badge>
                  </p>
  
                  <p class="text-primary h4 mt-1">
                    Number of ads:
                    <b-badge variant="info">{{ subscriptionsModal.ads_limit }}</b-badge>
                  </p>
  
  
                  <p class="text-primary h4 mt-1">
                   Payment Provider:
                    <b-badge variant="info">{{ subscriptionsModal.payment_provider.name }}</b-badge>
                  </p>
  
  
  
                 
                  <b-col md="12" >
                      <p class="text-secondary h4 mt-1">User :</p>
                      <table class="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Phone</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                            <td dir="rtl"> {{ subscriptionsModal.user.firstname + ' ' + subscriptionsModal.user.lastname }}</td>
                            <td style="text-align: center"> {{ subscriptionsModal.user.phone}}</td>
                          </tr>
                        </tbody>
                      </table>
                  </b-col>
  
  
  
                  <b-col md="12" class="mt-4" >
                      <p class="text-secondary h4 mt-1">package</p>
                      <table class="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">code</th>
                            <th scope="col">Ads Limit</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          <tr >
                            <td dir="rtl"> {{ JSON.parse(JSON.parse(subscriptionsModal.package_snapshot).name).name_en }}</td>
                            <td style="text-align: center"> {{ JSON.parse(subscriptionsModal.package_snapshot).code }}</td>
                            <td>
                              <b-badge>
                                {{ JSON.parse(subscriptionsModal.package_snapshot).ads_limit }}
                              </b-badge>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </b-col>
  
                  <b-col md="12" class="mt-4" v-if="subscriptionsModal.transactions.length > 0 ">
                      <p class="text-secondary h4 mt-1">Transactions</p>
                      <table class="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">TRANSACTION REF	</th>
                            <th scope="col">TRANSACTION TYPE	</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">REMAINING AMOUNT</th>
                            <th scope="col">ACTION</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(transaction,index) in subscriptionsModal.transactions" :key="index">
                            <td dir="rtl"> {{transaction.transaction_ref }}</td>
                            <td dir="rtl"> {{transaction.transaction_type }}</td>
                            <td dir="rtl"> {{transaction.status }}</td>
                            <td dir="rtl"> {{transaction.amount }}</td>
                            <td dir="rtl"> {{transaction.remaining_amount }}</td>
                            <td>
                              <b-button
                                style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                                class="btn-icon rounded-circle"
                                :to="{ path: '/payment/show/' + transaction.id }"
                                variant="success"
                                >
                                <feather-icon
                                  style="width: 18px; height: 18px"
                                  icon="EyeIcon"
                                />
                              </b-button>
                            </td>
                           
                          </tr>
                        </tbody>
                      </table>
                  </b-col>
  
                  <b-col md="12" class="mt-4 mb-2"  v-if="subscriptionsModal.loggers.length > 0 ">
                      <p class="text-secondary h4 mt-1">Loggers</p>
                      <table class="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">note	</th>
                          
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(logger,index) in subscriptionsModal.loggers" :key="index">
                            <td dir="rtl"> {{logger.note }}</td>
                          
                           
                          </tr>
                        </tbody>
                      </table>
                  </b-col>
  
  
  
  
  
  
                  <p class="text-primary h4 mt-1">
                    Created At:
                    <b-badge variant="info">{{ subscriptionsModal.created_at | formatDate }}</b-badge>
                  </p>
                  <p class="text-primary h4 mt-1">
                    Updated At:
                    <b-badge variant="info">{{ subscriptionsModal.updated_at | formatDate }}</b-badge>
                  </p>
  
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
        </b-row>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" @change="fetchData" v-model="perPage" size="sm" :options="pageOptions"
              class="w-50" />
          </b-form-group>
          <!-- pagination -->
          <div>
            <b-pagination @input="fetchData()" aria-controls="CategoryTable" v-model="currentPage" :total-rows="rows"
              :per-page="perPage" align="center" class="my-0" />
          </div>
        </b-card-body>
      </b-card>
    </div>
  </template>
      
<script>
import { VueEditor } from 'vue2-editor'
import { required, integer } from '@validations'

  export default {
    name: 'subscription-table',
    props: ['link'],
   
    data() {
      return {
        authPermissions: null,
        deactive: '0',
        areaID: null,
        deletedArea: '0',
        showDismissibleAlert: false,
        errors_back: [],
        errors_delete: {},
        perPage: 25,
        pageOptions: [25, 50, 100],
        totalRows: 1,
        isBusy: false,
        currentPage: 1,
        sortBy: 'created_at',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        PackagesOption: [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ],
  
  
        statusOption: [
          {
            value: 'select_value',
            text: 'Select Value',
          },
          {
            value: 'pending',
            text: 'Pending',
          }, {
            value: 'failed',
            text: 'Failed',
          }, {
            value: 'success',
            text: 'Success',
          }, {
            value: 'Cancelled',
            text: 'Cancelled',
          }, {
            value: 'Refunded',
            text: 'Refunded',
          }
        ],
  
  
  
        statusFilterOption: [
          {
            value: 'pending',
            text: 'Pending',
          }, {
            value: 'failed',
            text: 'Failed',
          }, {
            value: 'success',
            text: 'Success',
          }, {
            value: 'Cancelled',
            text: 'Cancelled',
          }, {
            value: 'Refunded',
            text: 'Refunded',
          }
        ],
        
        payments: [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ],
        required,
        subscriptionsModal: '',
        filter: '',
        TypeModelFilter: '',
  
        AllBody: '',
        modalShow: false,
  
        fields: [
          {
            key: 'id',
            label: 'Id',
            sortable: true,
          },
          {
            key: 'code',
            label: 'code',
          },
         
     
          {
            key: 'user',
            label: 'User Name',
          },
          {
            key: 'packages',
            label: 'packages Name',
          },
  
          {
            key: 'subscription_status',
            label: 'Status',
          },
          {
            
            key: 'payment_provider',
            label: 'Payment Provider',
          },
          {
            key: 'total_paid',
            label: 'total_paid',
          },
          {
            key: 'created_at',
            label: 'Created_At',
            sortable: true,
          },
          {
            key: 'updated_at',
            label: 'Updated_At',
            sortable: true,
          },
  
          {
            key: 'actions',
            label: 'actions',
          },
  
  
  
        ],
        status: 'all',
        statusName: 'All',
        items: [],
        exportItems: [],
  
        updatedSubscription: {
          subscription_status_array: "",
        },
        paymet_methods: [],
      }
    },
    created() {
      this.authPermissions = this.$store.state.authPermissions
      // request tags index
      this.fetchData()

      axios
        .get('payment-providers')
        .then((result) => {
          const data = result.data.data;
  
          for (let cat in data) {
            this.payments.push({
              value: data[cat].id.toString(),
              text: data[cat].name
            });
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
  
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({
            text: f.label,
            value: f.key,
          }))
      },
      rows() {
        return this.totalRows
      },
    },
    watch: {
      sortDirection: function () {
        this.sortingTable()
      },
      status: function () {
        this.fetchData()
      },
      filter: function () {
        if (this.filter == '' || this.filter == null) {
          this.fetchData()
        }
      },
    },
    methods: {
      // fetch data of areas
      fetchData() {
        var url = null
        if (this.filter == '') {
          url =
          this.link +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.status +
            '?page=' +
            this.currentPage
        } else {
          url =
             this.link +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.status +
            '/' +
            this.filter +
            '?page=' +
            this.currentPage
        }
        const promise = axios.get(url, { "model_type": this.TypeModelFilter })
        return promise
          .then((result) => {
            this.totalRows = result.data.data.data.total;
            this.items.length = 0;
            this.items = result.data.data.data;
            this.exportItems = this.items;
            this.$refs.admint.refresh();
            return this.items
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      // sort function
      sortingTable() {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        var url = null
        if (this.filter == '') {
          url =
            'packages/index/' +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '?page=' +
            this.currentPage
        } else {
          url =
            'packages/index/' +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.filter +
            '?page=' +
            this.currentPage
        }
        const promise = axios.get(url, { "model_type": this.TypeModelFilter })
        return promise
          .then((result) => {
  
            this.totalRows = result.data.data.meta.total
            this.items.length = 0
            this.items = result.data.data.data
            this.exportItems = this.items
            this.$refs.admint.refresh()
            return this.items
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
  
      // delete function to delete area
      deleteData(id) {
        this.$bvModal
          .msgBoxConfirm(
            'Please confirm that you want to delete post category.',
            {
              title: 'Are You Sure?',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value == true) {
              axios
                .delete('post-categories/' + id)
                .then((result) => {
                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.fetchData()
                })
                .catch((err) => {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back.length = 0
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      })
                    } else {
                      this.errors_back = err.response.data.data
                    }
                    this.showDismissibleAlert = true
                  } else {
                    this.errors_back = []
                    this.errors_back.push({
                      error: 'internal server error',
                    })
                    this.showDismissibleAlert = true
                  }
                })
            }
          })
      },
  
  
  
      updateSubscriptionModal(item) {
        this.errors_back2 = []
        this.showDismissibleAlert = false
        // this.updatedSubscription = item
        this.updatedSubscription.id = item.id;
  
        this.updatedSubscription.subscription_status_array = item.subscription_status;
  
  
        // var package_id = this.updatedSubscription.package_id;
  
  
        // const value = this.PackagesOption.filter((item) => {
        //   if (item.value == package_id) 
        //   {
        //     this.updatedSubscription.package_id = {};
        //     this.updatedSubscription.package_id.value = item.value;
        //     this.updatedSubscription.package_id.text = item.text;
        //   }
        // });
        
       
  
  
        // var payment_provider_id = this.updatedSubscription.payment_provider_id;
  
        // const payment_provider = this.payments.filter((item) => {
  
        //     if (item.value == payment_provider_id) {
        //       this.updatedSubscription.payment_provider_id = {};
        //       this.updatedSubscription.payment_provider_id.value = item.value;
        //       this.updatedSubscription.payment_provider_id.text = item.text;
        //     }
  
        //     });
  
  
            
  
  
  
      
      },
  
  
      changeStatus(name, slug) {
        this.status = slug
        this.statusName = name
      },
  
      deleteSubscription(id) {
        this.$bvModal
          .msgBoxConfirm("Please confirm that you want to delete This subscription.", {
            title: "Are You Sure?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios
                .delete('subscriptions/' + id)
                .then((result) => {
                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.fetchData();
                })
                .catch((err) => {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: "Error!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.errors_back.length = 0;
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      });
                    } else {
                      this.errors_back = err.response.data.data;
                    }
                    this.showDismissibleAlert = true;
                  } else {
                    this.errors_back = [];
                    this.errors_back.push({
                      error: "internal server error",
                    });
                    this.showDismissibleAlert = true;
                  }
                });
            }
          });
      },
  
  
      updateSubscription(item) {
        this.$refs.updatedSubscriptionForm.validate().then((success) => {
          //  data{'hex_code' : this.hex_code}
          // fetch data of values
          let { updatedSubscription, paymet_methods } = this;
          axios
            .put(
              'subscriptions/' + updatedSubscription.id,
               updatedSubscription 
            )
            .then((result) => {
              // get the value from values array to update the hex code
              const value = this.items.filter((item) => {
                if (item.id == this.updatedSubscription.id) {
  
                  item.subscription_status = this.updatedSubscription.subscription_status_array.value
                  this.updateSubscriptionModal(item);
                }
              })
              this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
              })
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch((err) => {
              this.errors_back2.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back2.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back2 = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back2 = []
                this.errors_back2.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        })
      },
  
      packagesQuickView(item, index, event) {
        this.$bvModal.show('packages-quick-view-modal')
        this.subscriptionsModal = item
      },
  
  
      addpaymetmethods() {
        this.paymet_methods.push({});
      },
      subpaymetmethods(index) {
        this.paymet_methods.splice(index, 1);
  
      },
  
  
  
      genPDF() {
        const { jsPDF } = require("jspdf");
        const doc = new jsPDF()
        let print_body = []
        for (let index = 0; index < this.items.length; index++) {
          print_body[index] = [
            this.items[index].id,
            this.items[index].name,
            this.items[index].description,
            this.items[index].price,
            this.items[index].num_ads,
            this.items[index].created_at,
            this.items[index].updated_at,
          ]
        }
        doc.autoTable({
          head: [['ID', 'Name', 'Description', 'price', 'num_ads', 'CREATED_AT', 'UPDATED_AT']],
          body: print_body,
        })
        doc.save('table.pdf')
      },
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
    
    
    
  <style>
  .modal-backdrop {
    opacity: 0.1;
    background-color: #22292f;
    position: fixed;
    top: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
  }
  
  .packagess-table {
    max-height: 70vh !important;
  }
  
  .m-50 {
    margin-left: 50px;
  }
  
  .badge {
    white-space: pre-wrap !important;
  }
  </style>
      