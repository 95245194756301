<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- start data table -->
    <b-card title="payment sessions" class="w-100">
      <b-row>
        <!-- sorting Status -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="Status"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              :dropup="true"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="status"
              class="border mr-0"
              size="sm"
              :text="statusName"
              variant="flat-primary"
            >
              <div style="overflow-y: scroll; max-height: 350px">
                <b-dropdown-item @click="changeStatus('All', 'all')">
                  All
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeStatus(item.name, item.slug)"
                  v-for="(item, index) in paymentSessionStatus"
                  :key="index"
                >
                  {{ item.name }}
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </b-form-group>
        </b-col>

        <!-- sorting -->
        <b-col md="3" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              ></b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class=""
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" />
                  Download Excel
                </b-dropdown-item>
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" />
                Save PDF
              </b-dropdown-item>
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- actions -->
        <b-col cols="12 ">
          <b-table
            id="PaymentSessionsTable"
            ref="admint"
            striped
            responsive
            :items="items"
            :fields="fields"
            class="position-relative"
            @row-clicked="paymentSessionQuickView"
          >
            <template #cell(payment_provider)="data">
              <b-img-lazy width="40" :src="data.value.logo" />
            </template>

            <template #cell(payment_method)="data">
              {{ data.value.fees }} %
            </template>
            <template #cell(seller_username)="items">
              <router-link
                :to="{ path: '/User/Show/' + items.item.seller_id }"
                v-if="authPermissions.includes('user')"
                class="text-primary"
              >
                {{ items.item.seller_username }}
              </router-link>
              <p v-else>{{ items.item.seller_username }}</p>
            </template>
            <template #cell(buyer_username)="items">
              <router-link
                :to="{ path: '/User/Show/' + items.item.buyer_id }"
                v-if="authPermissions.includes('user')"
              >
                {{ items.item.buyer_username }}
              </router-link>
              <p v-else>{{ items.item.buyer_username }}</p>
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.value == 'pending'
                    ? 'light-primary'
                    : data.value == 'delivered'
                    ? 'light-success'
                    : data.value == 'failed'
                    ? 'light-danger'
                    : 'light-secondary'
                "
              >
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(actions)="items">
              <div class="text-center d-flex p-1">
                <!-- Show Order -->
                <b-button
                  v-if="authPermissions.includes('show')"
                  variant="light-danger"
                  type="submit"
                  class="my-1 mx-0 pt-1 px-1"
                  :to="{ path: '/paymentSessions/show/' + items.item.id }"
                >
                  <img
                    width="30"
                    :src="require('@/assets/images/icons/eye.png')"
                  />
                </b-button>

                <b-button
                  v-if="
                    authPermissions.includes('show') &&
                    items.item.status == 'pending'
                  "
                  variant="light-danger"
                  type="submit"
                  class="my-1 mx-0 pt-1 px-1"
                  :to="{
                    path: '/paymentSessions/realtime/show/' + items.item.id,
                  }"
                >
                  <img
                    :src="require('@/assets/images/icons/live-streaming.svg')"
                  />
                </b-button>

                <!-- </b-button> -->
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-1">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
  
  <script>
export default {
  name: 'PaymentSessions-table',
  props: ['link'],

  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fildes
      paymentSessionStatus: [
        {
          name: 'Pending',
          slug: 'pending',
        },
        {
          name: 'Delivered',
          slug: 'delivered',
        },
        {
          name: 'Canceled',
          slug: 'canceled',
        },
        {
          name: 'Failed',
          slug: 'failed',
        },
        {
          name: 'Dispute',
          slug: 'dispute',
        },
        {
          name: 'Refund',
          slug: 'refund',
        },
      ],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'buyer_username',
          label: 'Buyer Username',
        },
        {
          key: 'seller_username',
          label: 'Seller Username',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'total_paid',
          label: 'Total',
        },
        {
          key: 'margin_amount',
          label: 'Margin Amount',
        },
        {
          key: 'payout_amount',
          label: 'Payout Amount',
        },
        {
          key: 'payment_provider',
          label: 'Payment Provider',
        },
        {
          key: 'payment_method',
          label: 'Payment Fees',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      items: [],
      exportItems: [],
      statusName: 'all',
      status: 'all',
      // permissions:[]
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request Coupons index
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    statusName: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },

    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    // fetch data of Coupons
    fetchData() {
      var url = null
      if (this.filter == '') {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.statusName +
          '?page=' +
          this.currentPage
      } else {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.statusName +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          const data = result.data.data.data
          this.totalRows = result.data.data.meta.total
          this.items.length = 0

          this.items = data

          this.exportItems = this.items
          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          //console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    // search function

    printEle() {
      window.print()
    },
    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].vendor,
          this.items[index].status,
          this.items[index].type,
          this.items[index].rate,
          this.items[index].price,
        ]
      }
      doc.autoTable({
        head: [['ID', 'Vendor', 'Status', 'Type', 'Rate', 'Price']],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
  