<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5;"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="removeExitem"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="BundleTable"
            ref="admint"
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(product1)="items">
              <div>
                <b-card
                  bg-variant="light"
                  :title="items.item['product1'].title"
                  :img-src="items.item['product1'].cover"
                  img-alt="Image"
                  img-top
                  tag="article"
                  style="max-width: 20rem;"
                  class="mb-2"
                >
                  <b-card-text>
                    {{ items.item['product1'].body }}
                  </b-card-text>
                  <b-button
                    variant="primary"
                    :disabled="items.item['product1'].slug == null"
                    v-if="authPermissions.includes('show')"
                    size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    :to="{
                      path:
                        '/ShowProduct/Variant/' +
                        items.item['product1'].productID,
                      query: { slug: items.item['product1'].slug },
                    }"
                    >Show</b-button
                  >
                </b-card>
              </div>
            </template>
            <template #cell(product2)="items">
              <div>
                <div v-if="items.item['product2']">
                  <b-card
                    bg-variant="light"
                    :title="items.item['product2'].title"
                    :img-src="items.item['product2'].cover"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2"
                  >
                    <b-card-text>
                      {{ items.item['product2'].body }}
                    </b-card-text>
                    <b-button
                      variant="primary"
                      :disabled="items.item['product2'].slug == null"
                      v-if="authPermissions.includes('show')"
                      size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      :to="{
                        path:
                          '/ShowProduct/Variant/' +
                          items.item['product2'].productID,
                        query: { slug: items.item['product2'].slug },
                      }"
                      >Show</b-button
                    >
                  </b-card>
                </div>
              </div>
            </template>

            <template #cell(product3)="items" v-if="items.item">
              <div v-show="items.item">
                <b-card
                  v-if="items.item['product3'].title != null"
                  :title="items.item['product3'].title"
                  :img-src="items.item['product3'].cover"
                  img-alt="Image"
                  img-top
                  tag="article"
                  style="max-width: 20rem;"
                  class="mb-2"
                >
                  <b-card-text>
                    {{ items.item['product3'].body }}
                  </b-card-text>
                  <b-button
                    variant="primary"
                    :disabled="items.item['product3'].slug == null"
                    v-if="authPermissions.includes('show')"
                    size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    :to="{
                      path:
                        '/ShowProduct/Variant/' +
                        items.item['product3'].productID,
                      query: { slug: items.item['product3'].slug },
                    }"
                    >Show</b-button
                  >
                </b-card>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="BundleTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'compare-table',
  

  data() {
    return {
      authPermissions: null,
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fields
      fields: [
        {
          key: 'id',
          label: 'id',
        },
        {
          key: 'product1',
          label: 'Product 1',
        },
        {
          key: 'product2',
          label: 'Product 2',
        },
        {
          key: 'product3',
          label: 'Product 3',
        },
      ],
      items: [],
      exportItems: [],
    }
  },
  props: ['link'],
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDirection: function () {
      this.sortingTable()
    },
    sortBy: function () {
      this.sortingTable()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    // fetch Data of categories
    fetchData() {
      this.showDismissibleAlert = false
      // request category index API
      const promise = axios.get(
        this.link + this.perPage.toString() + '?page=' + this.currentPage
      )
      return promise
        .then((result) => {
          const data = result.data.data.data

          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          if (data.length > 0) {
            for (let index in data) {
              if (
                data[index].variants.length > 0 &&
                data[index].variants.length < 4
              ) {
                if ((data[index].variants.length = 3)) {
                  if (data[index].variants[1] != null) {
                    this.items.push({
                      id: data[index].id,
                      product1: {
                        title:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.name,
                        body:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.body,
                        cover:
                          data[index].variants[0].cover == null
                            ? ''
                            : data[index].variants[0].cover,
                        productID: data[index].variants[0].product.id,
                        slug:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.slug,
                      },
                      product2: {
                        title:
                          data[index].variants[1].translation == null
                            ? 'undefinded'
                            : data[index].variants[1].translation.name,
                        body:
                          data[index].variants[1].translation == null
                            ? 'undefinded'
                            : data[index].variants[1].translation.body,
                        cover:
                          data[index].variants[1].cover == null
                            ? ''
                            : data[index].variants[1].cover,
                        productID: data[index].variants[1].product.id,
                        slug:
                          data[index].variants[1].translation == null
                            ? 'undefinded'
                            : data[index].variants[1].translation.slug,
                      },
                    })
                  } else if (data[index].variants[2] != null) {
                    this.items.push({
                      id: data[index].id,
                      product1: {
                        title:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.name,
                        body:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.body,
                        cover:
                          data[index].variants[0].cover == null
                            ? ''
                            : data[index].variants[0].cover,
                        productID: data[index].variants[0].product.id,
                        slug:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.slug,
                      },
                      product2: {
                        title:
                          data[index].variants[1].translation == null
                            ? 'undefinded'
                            : data[index].variants[1].translation.name,
                        body:
                          data[index].variants[1].translation == null
                            ? 'undefinded'
                            : data[index].variants[1].translation.body,
                        cover:
                          data[index].variants[1].cover == null
                            ? ''
                            : data[index].variants[1].cover,
                        productID: data[index].variants[1].product.id,
                        slug:
                          data[index].variants[1].translation == null
                            ? 'undefinded'
                            : data[index].variants[1].translation.slug,
                      },
                      product3: {
                        title:
                          data[index].variants[2].translation == null
                            ? 'undefinded'
                            : data[index].variants[2].translation.name,
                        body:
                          data[index].variants[2].translation == null
                            ? 'undefinded'
                            : data[index].variants[2].translation.body,
                        cover:
                          data[index].variants[2].cover == null
                            ? ''
                            : data[index].variants[2].cover,
                        productID: data[index].variants[2].product.id,
                        slug:
                          data[index].variants[2].translation == null
                            ? 'undefinded'
                            : data[index].variants[2].translation.slug,
                      },
                    })
                  } else {
                    this.items.push({
                      id: data[index].id,
                      product1: {
                        title:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.name,
                        body:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.body,
                        cover:
                          data[index].variants[0].cover == null
                            ? ''
                            : data[index].variants[0].cover,
                        productID: data[index].variants[0].product.id,
                        slug:
                          data[index].variants[0].translation == null
                            ? 'undefinded'
                            : data[index].variants[0].translation.slug,
                      },
                    })
                  }
                }
              }
            }
          }
          // this.items = [...new Map(this.items.map(item => [item.id, item])).values()] this.exportItems = this.items this.$refs.admint.refresh();
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    sortingTable() {
      this.showDismissibleAlert = false
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      const promise = axios.get(
        this.link + this.perPage.toString() + '?page=' + this.currentPage
      )
      return promise
        .then((result) => {
          const data = result.data.data.data
          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          if (data.length > 0) {
            for (let index in data) {
              if (data[index].variants.length > 1) {
                if (data[index].variants[2] != null) {
                  this.items.push({
                    product1: data[index].variants[0],
                    product2: data[index].variants[1],
                    product3: data[index].variants[2],
                  })
                } else {
                  this.items.push({
                    product1: data[index].variants[0],
                    product2: data[index].variants[1],
                  })
                }
              }
            }
          }
          this.items = [
            ...new Map(this.items.map((item) => [item.id, item])).values(),
          ]
          this.exportItems = this.items
          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // delete function to delete category
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete bundle.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('bundles/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].icone
      }
    },
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].vendor,
          this.items[index].active,
          this.items[index].type,
          this.items[index].amount,
          this.items[index].bundle_price,
          this.items[index].end_date,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [
          [
            'ID',
            'Vendor',
            'Active',
            'Type',
            'Amount',
            'Bundle Price',
            'End Date',
            'CREATED_AT',
            'UPDATED_AT',
          ],
        ],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
