<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- start data table -->
    <b-card>
      <b-row>
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                @change="sortingTable"
                v-model="sortBy"
                :options="sortOptions"
                class=""
              >
              </b-form-select>
              <b-form-select
                @change="sortingTable"
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class=""
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                @change="sortingTable"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="fetchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5;"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>

        <!-- actions -->
        <b-col cols="12">
          <b-table
            id="OrdersTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
          
            <template #cell(status)="data">
              <b-badge variant="primary" v-if="data.value">
                {{ data.value }}
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'transaction-table',
  props: ['link'],
 
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'id',
      status: 'all',
      statusName: 'All',
      orderStatus: [],
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'type',
          label: 'type',
        },

        {
          key: 'status',
          label: 'status',
        },
        {
          key: 'amount',
          label: 'amount',
        },
        {
          key: 'total_trasaction',
          label: 'Total Transaction',
        },
        {
          key: 'order_code',
          label: 'Order Code',
        },

        {
          key: 'refund_code',
          label: 'refund code',
        },
        {
          key: 'note',
          label: 'note',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
      ],
      items: [],
      exportItems: [],
    }
  },
  created() {
    // request Orders index
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDirection: function () {
      this.sortingTable()
    },
    sortBy: function () {
      this.sortingTable()
    },
    status: function () {
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    // fetch data of Orders
    fetchData() {
      var url = null
      if (this.filter == '') {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {

          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          this.items = result.data.data.data
          this.exportItems = this.items
          return this.items
        })
        .catch((err) => {
          
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    // sort function
    sortingTable() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      var url = null
      if (this.filter == '') {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url =
          this.link +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          const data = result.data.data.data
          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          if (data.length > 0) {
            for (let index in data) {
              this.items.push({
                id: data[index].id,
                typr: data[index].type,
                status: data[index].transaction_log.status,
                total: data[index].transaction_log.total,
                amount: data[index].amount,
                refundCode: data[index].refund.code,
                orderCode: data[index].transaction_log.order.code,
                note: data[index].note,
                created_at: data[index].created_at,
                updated_at: data[index].updated_at,
              })
            }
          }
          this.items = [
            ...new Map(this.items.map((item) => [item.id, item])).values(),
          ]
          this.exportItems = this.items
          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].username,
          this.items[index].code,
          this.items[index].orderstatus,
          this.items[index].totla,
          this.items[index].discount,
          this.items[index].shipping_price,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [
          [
            'ID',
            'User Name',
            'Code',
            'Order Status',
            'Totla',
            'Discount',
            'Shipping Price',
            'CREATED_AT',
            'UPDATED_AT',
          ],
        ],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
