<template>
    <div>
      <!-- error handelr -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">
          Alert
        </h4>
        <div class="alert-body">
          <ul v-for="values in errors_back" :key="values">
            <li v-for="value in values" :key="value">{{ value }}</li>
          </ul>
        </div>
      </b-alert>
      <!-- start data table -->
      <b-card>
        <b-row>
          <!-- sorting Status -->
          <b-col md="2" class="my-1">
            <b-form-group
              label="Status"
              label-cols-sm="4"
              label-align-sm="right"
              label-size="sm"
            >
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                style="z-index: 9999999;"
                v-model="status"
                class="border mr-0"
                size="sm"
                :text="statusName"
                variant="flat-primary"
              >
                <b-dropdown-item @click="changeStatus('All', 'all')">
                  All
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeStatus(item.name, item.slug)"
                  v-for="(item, index) in adsStatus" :key="index"
                >
                  {{ item.name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-col>
          <!-- sorting -->
          <b-col md="3" class="my-1">
            <b-form-group
              label="Sort By"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class=""
                >
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class=""
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- filter -->
          <b-col md="3" class="my-1">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button variant="primary"  @click="searchData">
                    Search
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="1" class="my-1">
            <!-- print and save pdf & excel -->
            <div class="ml-auto mr-2">
              <b-dropdown
                style="height: 2.142rem; line-height: 0.5;"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                right
                variant="primary"
                size="sm"
                class="dropdown-icon-wrapper"
              >
                <template #button-content
                  ><feather-icon
                    icon="PrinterIcon"
                    size="16"
                    class="align-middle"
                  />
                </template>
                <download-excel :data="exportItems">
                  <b-dropdown-item>
                    <feather-icon icon="DownloadIcon" /> Download
                    Excel</b-dropdown-item
                  >
                </download-excel>
                <b-dropdown-divider />
                <b-dropdown-item @click="genPDF">
                  <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
                >
                <b-dropdown-divider />
              </b-dropdown>
            </div>
          </b-col>
  
          <!-- actions -->
          <b-col cols="12">
            <b-table
              id="OrdersTable"
              ref="admint"
              striped
              hover
              responsive
              show-empty
              :busy.sync="isBusy"
              class="position-relative"
              :items="items"
              :fields="fields"
            >
              <template #cell(user)="items">
                <!-- show user -->
                <b-button
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-info"
                  type="submit"
                  :to="{ path: '/User/Show/' + items.item.userId }"
                >
                  {{ items.item.user }}
                </b-button>
              </template>
              <template #cell(status)="data">
                <b-badge variant="primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(actions)="items">
                <div>
                  <!-- update Order Status -->
                  <b-button
                    size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    type="submit"
                    :to="{ path: '/ads/show/' + items.item.id }"
                  >
                    
              <feather-icon style="width: 18px; height: 18px;" icon="EyeIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              @change="fetchData"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
          <!-- pagination -->
          <div>
            <b-pagination
              @input="fetchData()"
              aria-controls="CategoryTable"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="center"
              class="my-0"
            />
          </div>
        </b-card-body>
      </b-card>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'problem-table',

    props: ['link'],
    data() {
      return {
        authPermissions: null,
        showDismissibleAlert: false,
        errors_back: [],
        errors_delete: {},
        perPage: 25,
        pageOptions: [25, 50, 100],
        totalRows: 1,
        isBusy: false,
        currentPage: 1,
  
        sortBy: 'created_at',
        status: 'all',
        statusName: 'All',
        adsStatus: [
          {
            name: 'Enabled',
            slug: 'enabled',
          },
          {
            name: 'Disabled',
            slug: 'disabled',
          },
          {
            name: 'Review',
            slug: 'review',
          },
          {
            name: 'Rejected',
            slug: 'rejected',
          },
          {
            name: 'Confirmed',
            slug: 'confirmed',
          },
          {
            name: 'Blocled',
            slug: 'blocked',
          },
        ],
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        // table fields
        fields: [
          {
            key: 'id',
            label: 'Id',
            sortable: true,
          },
        //   {
        //     key: 'item_cover',
        //     label: 'Image',
        //   },
          {
            key: 'code',
            label: 'Code',
          },
          {
            key: 'item_name',
            label: 'Name',
          },
          {
            key: 'status',
            label: 'Status',
          },
          {
            key: 'phone',
            label: 'Phone',
          },
          {
            key: 'sale_price',
            label: 'Sale Price',
          },
          {
            key: 'battery_health',
        label: 'Battery Health',
          },
          {
            key: 'created_at',
            label: 'Created_At',
            sortable: true,
          },
          {
            key: 'updated_at',
            label: 'Updated_At',
            sortable: true,
          },
          {
            key: 'actions',
          },
        ],
        items: [],
        exportItems: [],
      }
    },
    created() {
      this.authPermissions = this.$store.state.authPermissions
      // request Orders index
      this.fetchData()
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({
            text: f.label,
            value: f.key,
          }))
      },
      rows() {
        return this.totalRows
      },
    },
    watch: {
      sortDirection: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      sortBy: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      status: function () {
        this.fetchData()
      },
      filter: function () {
        if (this.filter == '' || this.filter == null) {
          this.fetchData()
        }
      },
    },
    methods: {
      // fetch data of Orders
      fetchData() {
        var url = null
        if (this.filter == '') {
          url =
            this.link +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.status +
            '?page=' +
            this.currentPage
        } else {
          url =
            this.link +
            this.perPage.toString() +
            '/sortBy-' +
            this.sortBy +
            '-' +
            this.sortDirection +
            '/' +
            this.status +
            '/' +
            this.filter +
            '?page=' +
            this.currentPage
        }
        const promise = axios.get(url)
        return promise
          .then((result) => {
            
            this.totalRows = result.data.data.meta.total
            this.items.length = 0
           
            this.items = result.data.data.data
            this.exportItems = this.items
            return this.items
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      searchData() {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
  
      // delete function of (delete button) Order
      deleteData(id) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete order.', {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios
                .delete('orders/' + id)
                .then((result) => {
                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.fetchData()
                })
                .catch((err) => {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back.length = 0
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      })
                    } else {
                      this.errors_back = err.response.data.data
                    }
                    this.showDismissibleAlert = true
                  } else {
                    this.errors_back = []
                    this.errors_back.push({
                      error: 'internal server error',
                    })
                    this.showDismissibleAlert = true
                  }
                })
            }
          })
      },
      changeStatus(name, slug) {
        this.status = slug
        this.statusName = name
      },
      genPDF() {
      const { jsPDF } = require("jspdf");
        const doc = new jsPDF()
        let print_body = []
        for (let index = 0; index < this.items.length; index++) {
          print_body[index] = [
            this.items[index].id,
            this.items[index].username,
            this.items[index].code,
            this.items[index].adsstatus,
            this.items[index].totla,
            this.items[index].discount,
            this.items[index].shipping_price,
            this.items[index].created_at,
            this.items[index].updated_at,
          ]
        }
        doc.autoTable({
          head: [
            [
              'ID',
              'User Name',
              'Code',
              'Order Status',
              'Totla',
              'Discount',
              'Shipping Price',
              'CREATED_AT',
              'UPDATED_AT',
            ],
          ],
          body: print_body,
        })
        doc.save('table.pdf')
      },
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
  