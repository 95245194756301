<template>
  <div v-if="userData">
    <!-- handle error -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col md="5" class="mb-2">
        <b-card class="h-100 cardH">
          <!-- User Info: Left col -->
          <!-- User Avatar & Action Buttons -->
          <b-row>
            <b-col md="6">
              <CoolLightBox :items="profileArr" :index="imgIndex" @close="imgIndex = null">
              </CoolLightBox>
              <b-img rounded @click="imgIndex = 0" :src="userData.profile_picture" :alt="userData.firstname"
                style="max-width: 100%; height: auto;" />
            </b-col>
            <b-col md="6">
              <h4 class="mb-1">{{ userData.username }}</h4>
              <h5 class="mb-1">
                <feather-icon icon="MailIcon" class="mr-75 " style="color:#0D6EFD; " />Email:<span style="color:black"
                  class="card-text">
                  {{ userData.email }}</span>
              </h5>
              <h5 class="mb-1">
                <feather-icon icon="UserIcon" class="mr-75" style="color:#0D6EFD; " />First name:
                <span style="color:black" class="card-text">
                  {{ userData.firstname }}</span>
              </h5>
              <h5 class="mb-1">
                <feather-icon icon="UserIcon" class="mr-75" style="color:#0D6EFD; " />Last Name:
                <span style="color:black" class="card-text">
                  {{ userData.lastname }}</span>
              </h5>
              <h5 class="mb-1">
                <feather-icon icon="CalendarIcon" class="mr-75" style="color:#0D6EFD; " />Birthdate:
                <span style="color:black" class="card-text">
                  {{ userData.birthdate }}</span>
              </h5>
              <h5 class="mb-1">
                <feather-icon icon="FlagIcon" class="mr-75" style="color:#0D6EFD; " />Address:<span style="color:black"
                  class="card-text">
                  {{ userData.address }}</span>
              </h5>
              <h5 class="mb-1">
                <feather-icon icon="PhoneIcon" class="mr-75" style="color:#0D6EFD; " />Phone:<span style="color:black"
                  class="card-text">
                  {{ userData.phone }}</span>
              </h5>
            </b-col>
          </b-row>
          <!-- Right Col: Table -->
        </b-card>
      </b-col>
      <b-col md="2" class="mb-2">
        <b-card class="h-100 " v-if="authPermissions.includes('user_withdrawal')">
          <statistic-card-vertical icon="DollarSignIcon" :statistic="userData.wallet_amount + ' EGP'"
            statistic-title="Wallet" color="success" />
          <b-button variant="warning" v-if="authPermissions.includes('update')" block
            :to="{ path: '/User/withdrawal/' + $route.params.id }">withdrawal</b-button>
        </b-card>
      </b-col>
      <b-col md="5" class="mb-2">
        <b-card class="h-100">
          <div v-if="delUser" class="d-flex justify-content-center">
            <b-button block v-if="authPermissions.includes('update')" :to="{ path: '/User/Update/' + userData.id }"
              variant="primary mr-1 mt-1">
              Edit profile
            </b-button>


            <b-button block v-if="authPermissions.includes('delete')" @click="deleteAdmin(userData.id)"
              variant="outline-danger" class=" mr-1 mt-1">
              Close Account
            </b-button>
          </div>

          <div v-if="delUser" class="d-flex justify-content-center">
            <b-button block v-if="authPermissions.includes('assign-free-package')" @click="assignPackageModal"
              variant="outline-success" class=" mr-1 mt-1">
              Assgin Free Package
            </b-button>

          </div>
          <div v-if="delUser" class="d-flex justify-content-center">
            <b-button block v-if="authPermissions.includes('verify-user') && userData.phone_verified_at == null"
              @click="verifyUserPhoneNumber(userData.id)" variant="outline-success" class=" mr-1 mt-1">
              Verify User Phone Number
            </b-button>
          </div>
          <div v-else class="d-flex justify-content-center">
            <b-button block v-if="authPermissions.includes('update')" @click="restoreAdmin(userData.id)"
              variant="outline-danger" class=" mr-1 mt-1">
              Restore Account
            </b-button>
          </div>
          <hr />
          <div v-if="delUser" class="d-flex justify-content-md-center align-items-center text-center">
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ userData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Updated at
              </h6>
              <h3 class="mb-0">
                {{ userData.updated_at }}
              </h3>
            </div>
          </div>
          <div v-else class="d-flex justify-content-md-center align-items-center text-center">
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ userData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Deleted at
              </h6>
              <h3 class="mb-0">
                {{ userData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- Assign Free Package in table for variant -->
    <b-modal id="assign-package-modal" ref="assign-package-modal" centered title="Assign Free Package" size="l"
      @ok="assignFreePackageRequest()" ok-title="Ok" cancel-title="Close">

      <validation-observer ref="simpleRules">
        <form ref="form" @submit.stop.prevent="updateCustomizeVariantPrice">
          <b-row>

            <b-col md="12">
              <b-form-group label="Packages" label-for="Packages">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="freePackages.map(pkg => ({ id: pkg.id, name: pkg.name, category_name: pkg.category_name, label: pkg.name + ' For Category ' + pkg.category_name }))"
                  v-model="selectedPackage" />
              </b-form-group>
            </b-col>

            <b-col md="12">
            <b-alert v-height-fade.appear fade :show="showAssignReportAlert" @dismissed="showAssignReportAlert = false"
              variant="danger">
         
              <div class="alert-body">
                <ul v-for="values in assignPackageErrors" :key="values">
                  <li v-for="value in values" :key="value">{{ value }}</li>
                </ul>
              </div>
            </b-alert>
          </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-card title="Wallet Transactions" v-if="userData.user_wallet_transactions.length != 0">
      <app-timeline>
        <app-timeline-item v-for="(item, index) in userData.user_wallet_transactions" :key="index" variant="primary">
          <p>
            By : {{ item.admin.email }} - ({{ item.admin.firstname }}
            {{ item.admin.lastname }})
          </p>
          <p>Amount: {{ item.amount }}</p>
          <p>Type: {{ item.type }}</p>
          <p>Note: {{ item.note }}</p>
          <a class="text-primary" :href="item.file">Press here to open file</a>
        </app-timeline-item>
      </app-timeline>
    </b-card>

    <b-card title="Addresses" v-if="userData.addresses.length != 0">
      <b-table id="AddressesTable" ref="AddressesTable" striped hover responsive show-empty class="position-relative"
        :items="addressData" :fields="addressFields">
        <template #cell(city)="data">
          <b-card-text>
            <div v-html="data.value.name"></div>
          </b-card-text>
        </template>


      </b-table>
    </b-card>

    <b-card>
      <b-tabs>
        <b-tab title="Orders">
          <orders-table :link="'users/' + id + '/orders-pagi/'"></orders-table>
        </b-tab>
        <b-tab title="Refunds" @click="refundsClick = true">
          <refund-table v-if="refundsClick" :link="'users/' + id + '/refunds-pagi/'"></refund-table>
        </b-tab>
        <b-tab title="Transactions" @click="transactionsClick = true">
          <user-wallet-transaction-table v-if="transactionsClick"
            :link="'users/' + id + '/wallet-pagi/'"></user-wallet-transaction-table>
        </b-tab>


        <b-tab title="Favorites" @click="favoritesClick = true">
          <b-tabs>
            <b-tab title="Variants">
              <favorite-variants-table v-if="favoritesClick"
                :link="'users/' + id + '/favorites/variants-pagi/'"></favorite-variants-table>
            </b-tab>
            <b-tab title="Items" @click="itemFavoritesClick = true">
              <items-table v-if="itemFavoritesClick" :link="'users/' + id + '/items-guide-pagi/'"></items-table>
            </b-tab>
            <b-tab title="Bundles" @click="bundleFavoritesClick = true">
              <bundles-table v-if="bundleFavoritesClick" :link="'users/' + id + '/bundles-pagi/'"></bundles-table>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- <b-tab title="Comparison" @click="comparisonsClick = true">
          <compare-table v-if="comparisonsClick"
            :link="'users/' + id + '/comparison-pagi/'"
          ></compare-table>
        </b-tab> -->
        <b-tab title="Problems" @click="problemsClick = true">
          <problem-table v-if="problemsClick" :link="'users/' + id + '/problems-pagi/'"></problem-table>
        </b-tab>


        <b-tab title="Subscriptions" @click="subscriptionClick = true">
          <subscription-table v-if="subscriptionClick"
            :link="'users/' + id + '/subscriptions-pagi/'"></subscription-table>
        </b-tab>


        <b-tab title="Payout Accounts" @click="payoutAccountsClick = true">
          <payoutaccounts-table v-if="payoutAccountsClick"
            :link="'users/' + id + '/payoutaccounts-pagi/'"></payoutaccounts-table>
        </b-tab>


        <b-tab title="Ads Products" @click="variantsAdsClick = true">
          <variants-ads-table v-if="variantsAdsClick"
            :link="'users/' + id + '/ads/variants-pagi/'"></variants-ads-table>
        </b-tab>


        <b-tab title="payment Sessions As Seller" @click="paymentSessionsClick = true">
          <payment-sessions-table v-if="paymentSessionsClick"
            :link="'users/' + id + '/seller/payment-sessions-pagi/'"></payment-sessions-table>
        </b-tab>

        <b-tab title="payment Sessions As Buyer" @click="paymentSessionsClick = true">
          <payment-sessions-table v-if="paymentSessionsClick"
            :link="'users/' + id + '/buyer/payment-sessions-pagi/'"></payment-sessions-table>
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import OrdersTable from "@/Components/OrdersTable";
import UserWalletTransactionTable from "@/Components/UserWalletTransactionTable";
import RefundTable from "@/Components/RefundTable";
import FavoriteVariantsTable from "@/Components/User/FavoriteVariantsTable";
import AdsTable from "@/Components/User/AdsTable.vue";
import ItemsTable from "@/Components/ItemsTable";
import BundlesTable from "@/Components/BundlesTable";
import ProblemTable from "@/Components/ProblemTable";
import SubscriptionTable from "@/Components/SubscriptionTable";
import PayoutaccountsTable from "@/Components/payoutAccountsTable";
import variantsAdsTable from "@/Components/VariantsAdsTable";
import paymentSessionsTable from "@/Components/PaymentSessionsTable";
import CompareTable from "@/Components/CompareTable";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    AdsTable,
    CoolLightBox,
    OrdersTable,
    FavoriteVariantsTable,
    ItemsTable,
    BundlesTable,
    CompareTable,
    StatisticCardVertical,
    UserWalletTransactionTable,
    RefundTable,
    ProblemTable,
    SubscriptionTable,
    PayoutaccountsTable,
    variantsAdsTable,
    paymentSessionsTable,
  },


  data() {
    return {
      freePackages: [],
      selectedPackage: null,
      id: 0,
      userData: null,
      errors_back: null,
      assignPackageErrors: null,
      showAssignReportAlert: false,
      showDismissibleAlert: false,
      authPermissions: [],
      profileArr: [],
      imgIndex: null,
      delUser: true,
      comparData: null,
      addressData: [],
      refundsClick: false,
      transactionsClick: false,
      adsClick: false,
      favoritesClick: false,
      variantFavoritesClick: false,
      itemFavoritesClick: false,
      bundleFavoritesClick: false,
      comparisonsClick: false,
      problemsClick: false,
      subscriptionClick: false,
      payoutAccountsClick: false,
      variantsAdsClick: false,
      paymentSessionsClick: false,
      addressFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'city',
          label: 'City',
        },
        {
          key: 'street',
          label: 'Street',
        },
        {
          key: 'special_mark',
          label: 'Special Mark',
        },
        {
          key: 'postal_code',
          label: 'Postal Code',
        },
        {
          key: 'flat_number',
          label: 'Flat Number',
        },
        {
          key: 'building_number',
          label: 'Building Number',
        },
        {
          key: 'floor_number',
          label: 'Floor Number',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },

      ]
    };
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    async assignPackageModal() {
      if (this.freePackages.length == 0) {
        await this.fetchFreePackageData()

      }

      this.$bvModal.show('assign-package-modal')
    },
    async assignFreePackageRequest() {
      await axios
        .get("users/" + this.id + "/packages/" + this.selectedPackage.id + "/assign")
        .then((result) => {

          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Package Assigned Success',
            showConfirmButton: false,
            timer: 1500,
          })

          this.freePackages = []
          this.selectedPackage = null


        })
        .catch((err) => {
      this.$bvModal.show('assign-package-modal')
            this.assignPackageErrors = [];
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.assignPackageErrors.push({
                error: err.response.data.data,
              });
            } else {
              this.assignPackageErrors = err.response.data.data;
            }
            this.showAssignReportAlert = true;
          } else {
            this.assignPackageErrors = [];
            this.assignPackageErrors.push({
              error: "internal server error",
            });
            this.showAssignReportAlert = true;
          }
        });
    },
    async fetchFreePackageData() {
      await axios
        .get("users/" + this.id + "/packages/available/get")
        .then((result) => {
          this.freePackages = result.data.data
          if(this.freePackages.length == 0){
            
            this.assignPackageErrors = [];
            this.assignPackageErrors.push({
              error: "there are no available packages for this user.",
            });
            this.showAssignReportAlert = true;
          }
          console.log(this.freePackages)
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    fetchData() {
      axios
        .get("users/" + this.id + "/show")
        .then((result) => {
          this.userData = result.data.data;
          this.addressData = result.data.data.addresses;

          this.profileArr.push(this.userData.profile_picture);
          if (this.userData.deleted_at != null) {
            this.delUser = false;
          } else {
            this.delUser = true;
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },

    verifyUserPhoneNumber(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to Verify user.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .put("users/" + id + "/verify/phoneNumber")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteAdmin(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete user.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("users/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    restoreAdmin(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore user.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "warning",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("users/" + id + "/restore")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style scoped>
.cardH {
  margin-bottom: 0px;
}
</style>
